/*Navbar*/


.nav-actual-logo {
  max-width: 100%;
  width: 3em;
  margin-right: -0.4em;

}
.nav-started-button {
  font-family: "questa-sans", sans-serif;
  width: 20em; /* Adjust width as needed */
  margin-left: 2em;
  background-color: #111827; /* Solid background color */
  border: 2px solid #111827; /* Match border color to background */
  color: white; /* Text color */
  font-size: 1.4em;
  border-radius: 10px; /* Rounded edges */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for all properties */
  font-weight: lighter;
}

.navbar {
  margin-top: 0.5em;
  background-color: rgba(255, 255, 255);
  padding: 0 2em; /* Remove vertical padding, keep horizontal padding */
  position: fixed;
  top: 0;
  margin-left: 1.3em;
  width: 94.5%;
  height: 6em; /* Set a fixed height for the navbar */
  display: flex;
  justify-content: space-between; /* Adjust spacing between logo and navbar items */
  align-items: center; /* Center the items vertically */
  z-index: 1000; /* Ensure the navbar is on top of other elements */
  color: #111827;
}

.navbar-logo {
  margin-top: -.1em;
  margin-right: auto; /* Pushes the navbar list items to the right */
  margin-left: .1em;
  font-family: "adobe-handwriting-tiffany", sans-serif;
  font-weight: bold;
  font-size: 1.8em;
  max-width: 100%;
  width: 8em;
  line-height: 4em; /* Match the navbar height to center the text */
}

.navbar-list {
  margin-right: 37em;
  margin-left: 6em;
  list-style-type: none;
  display: flex;
  gap: 5em;
  padding: 0;
  color: #111827;
}

.navbar-link,
.navbar-link:visited {
  text-decoration: none;
  font-size: 1.3em;
  color: #111827;
  font-family: "Apercu Mono Pro", sans-serif;
  font-weight: 600;
}

.navbar-link:hover {
  color: #555; /* Change to desired hover color */
}

.large-icon {
  font-size: 10em;
}

.no-underline {
  text-decoration: none;
  color: #111827;
}

