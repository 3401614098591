/*AboutUs*/

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.8;
  color: #333;
}

.about-sec-background {
  background-color: #111827;
  max-height: 100%;
  height: 40em;
}

.about-image {
  margin-left: 52em;
  max-width: 100%;
  max-height: 100%;
  width: 40em;
  margin-top: -30em;
  margin-bottom: 10em;
  border-radius: 1em;
}

.about-container {

  margin-top: 2em;
}

.motiv-header {
  margin-top: 1.5em;
  line-height: 1em;
  font-size: 7em;
  font-weight: bold;
  color: #111827;
  text-align: center;
  font-family: "questa-sans", sans-serif;
}

.mission-container {
  position: relative;
  top: 2em;
  margin-top: 3.5em;
  max-width: 100%;
  width: 25em;
  max-height: 100%;
  font-size: 1.6em;
  margin-left: 10em;
  margin-bottom: 2em;
  font-family: 'questa-sans', sans-serif;
  font-weight: bold;
  color: white;
  text-align: left;
}

.mentor-mission-container {
  max-height: 100%;
  margin-left: 12em;
  margin-top: 5em;
  max-width: 100%;
  width: 30em;
  line-height: 1.4em;
  font-family: 'questa-sans', sans-serif;
  color: white;
  font-size: 1.3em;
  text-align: left;
}

/* Media Queries */
