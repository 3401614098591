/*Pricing*/

.pricing-background-container {
    margin-top: 7em;
    background-color: #111827;
    max-width: 100%;
    margin-left: 0em;
    height: 110em;
    max-height: 100%;
    margin-bottom: 0em;
  }

  .pricing-title {
    font-size: 7em;
    font-weight: bold;
    color: white;
    text-align: center;
    padding: .5em 0; /* Adjust padding for space */
    font-family: "questa-sans", sans-serif;
    background-color: #111827;
    margin-bottom: 0em;
  }

  .pricing-started-button {
    font-family: "questa-sans", sans-serif;
    width: 25%;
    margin-left: 10.5em;
    margin-right: 12em;
    background-color: white;
    border: 2px solid black;
    color: #111827;
    padding: 10px 50px;
    font-size: 1.9em;
    border-radius: 10px; /* Rounded edges */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for all properties */
    font-weight: lighter;
    position: relative; /* Necessary for positioning the pseudo-element */
  }

  .pricing-square {
    background-color: white;
    z-index: 1; /* Ensure the white box is above the background */
    text-align: center;
    max-width: 100%; /* Set max-width to make the box smaller */
    width: 80em;
    height: 30em;
    margin-left: 20em;
    margin-bottom: 80em;
  }

  .pricing-square-title {
    background-color: #feffd2;
    height: 2em;
    font-size: 3em;
    font-weight: bold;
    color: #111827;
    font-family: "questa-sans", sans-serif;
  }

  .first-price {
    margin-top: .3em;
    margin-right: 7em;
    font-size: 7em;
    font-weight: bold;
    color: #111827;
    font-family: "questa-sans", sans-serif;
    margin-bottom: -.3em;
  }

  .first-price-subtitle {
    margin-top: 0em;
    margin-right: 24em;
    font-size: 2em;
    font-weight: bold;
    color: #111827;
    font-family: "questa-sans", sans-serif;
  }

  .first-price-description {
    color: #111827;
    font-family: "questa-sans", sans-serif;
    max-width: 100%;
    max-height: 100%;
    width: 40em;
    line-height: 1.5em;
    font-size: 1.2em;
    margin-left: 23em;
    margin-top: -11em;
  }

  .first-price-list-title {
    font-size: 1.2em;
    font-family: "questa-sans" sans-serif;
    color: #111827;
    margin-left: -14.5em;
  }

  .price-list {
    margin-top: 0.5em;
    margin-left: 22.3em;
    text-align: left;
    max-width: 100%;
    width: 39em;
    color: #111827;
    font-size: 1.2em;
    font-family: questa-sans, sans-serif;
  }

  .pricing-sec-square {
    background-color: white;
    z-index: 1; /* Ensure the white box is above the background */
    text-align: center;
    max-width: 100%; /* Set max-width to make the box smaller */
    width: 80em;
    height: 33em;
    margin-left: 20em;
    margin-top: -75em;
    margin-bottom: 8em;
  }

  .pricing-sec-square-title {
    background-color: #feffd2;
    height: 2em;
    font-size: 3em;
    font-weight: bold;
    color: #111827;
    font-family: "questa-sans", sans-serif;
  }

  .first-sec-price {
    margin-top: .55em;
    margin-right: 7em;
    font-size: 7em;
    font-weight: bold;
    color: #111827;
    font-family: "questa-sans", sans-serif;
    margin-bottom: -.3em;
  }

  .first-sec-price-subtitle {
    margin-top: 0em;
    margin-right: 24em;
    font-size: 2em;
    font-weight: bold;
    color: #111827;
    font-family: "questa-sans", sans-serif;
  }

  .first-sec-price-description {
    color: #111827;
    font-family: "questa-sans", sans-serif;
    max-width: 100%;
    max-height: 100%;
    width: 40em;
    line-height: 1.5em;
    font-size: 1.2em;
    margin-left: 23em;
    margin-top: -12.5em;
  }

  .first-sec-price-list-title {
    font-size: 1.2em;
    font-family: "questa-sans" sans-serif;
    color: #111827;
    margin-left: -6em;
  }

  .price-sec-list {
    margin-top: 0.5em;
    margin-left: 22.3em;
    text-align: left;
    max-width: 100%;
    width: 39em;
    color: #111827;
    font-size: 1.2em;
    font-family: "questa-sans", sans-serif;
  }
