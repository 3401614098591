/*Footer*/
.footer {
  background-color: #111827;
  text-align: center;
  font-family: 'questa-sans', sans-serif;
  border-top: 1px solid white;
  height: 2.5em;
  position: relative;
}

.footer-logo {
  margin-right: 80em;
  max-width: 100%;
  width: 2em;
  max-height: 100%;


}

.footer-logo-background {

  max-width: 100%;
  width: 2em;
  margin-top: 0.5em;
  margin-right: 50em;
  margin-left: 2em;

}
.footer-links-container {
  margin-left: 0em;
  max-width: 100%;
  width: 150em;

  background-color: #111827;
  height: 4em;
  margin-bottom: 3em;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5em;
  text-decoration: none;
  color: #111827;
}

.footer-link {
  margin-top: 0.4em;
  color: white;
  font-size: 1.5em;
  margin-left: 11em;
  margin-right: 13em;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-link:hover {
  color: white; /* Optional: Add a hover effect */
}

.instagram-icon {
  font-size: 2em; /* Adjust size as needed */
  color: white;
}

.no-underline {
  text-decoration: none;
}

