/* HomeTutor */
.HomeTutor {
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2em; /* Adjusted padding */
  margin-top: 2em;
  max-width: 100%;
  height: 100%;
  height: 40em;
  width: 25em;
  font-family: 'questa-sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10em;
}

.chicago-logo .tutor-college {
  width: 13em; /* Increased width for Chicago logo */
}

.tutor-image-container {
  width: 15em;
  height: 15em;
  margin-bottom: 0.5em; /* Reduced margin-bottom */
}

.tutor-image {
  width: 100%;
  height: 100%;
  height: 16.5em;
  object-fit: cover;
}

.tutor-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  text-align: left; /* Align text to the left */
  width: 100%; /* Ensure container takes full width */
}

.tutor-name {
  font-size: 3em;
  font-weight: 700;
  margin-top: 0.5em;
  margin-bottom: 0em;
  color: #111827;
  font-family: 'questa-sans', sans-serif;
}

.tutor-location {
  font-size: 1.1em;
  color: #111827;
  margin-top: -.5em;
  margin-bottom: 0em;
  width: 100%;
  letter-spacing: 0.05em;
}

.tutor-major {
  font-size: 1.2em;
  color: #111827;
  margin-top: 1em;
  margin-bottom: 10px;
  width: 100%;
  letter-spacing: 0.05em;
}

.home-tutor-link {
  text-decoration: none;
}

.tutor-college {
  margin-top: 4.5em; /* Adjusted margin-top */
  width: 8em;
  margin-bottom: 2em; /* Adjusted margin-bottom */
}

.mentor-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 7em;
  width: 100%;
  margin-top: -2em;
}

.mentors-title {
  font-size: 4.5em;
  font-weight: bold;
  color: white;
  text-align: center;
  padding: 1em 0; /* Adjust padding for space */
  margin: 0; /* Remove default margin */
  font-family: "questa-sans", sans-serif;
  background-color: #111827;
}

.passion-title {
  font-size: 4.5em;
  font-weight: bold;
  color: #111827;
  text-align: center;
  margin-top: 1em;
  margin-bottom: -2em;
  font-family: "questa-sans", sans-serif;
}

.value-title {
  position: relative;
  top: -.4em;
  font-size: 4.5em;
  font-weight: bold;
  color: white;
  margin-bottom: .5em;
  max-width: 100%;
  width: 12.5em;
  text-align: center;
  margin-top: 1em;

  margin-left: 1.25em;
  font-family: "questa-sans", sans-serif;
}

/* Media Queries */
