/*Home*/

.caption-container-p {

  background-color: white;
  margin-bottom: 8.5em;

}
.caption-uno {
  max-width: 100%;
  width:20em;
  margin-left: 20em;
}

.caption-dos {
  max-width: 100%;
  width:20em;
  margin-top: -5.5em;
  margin-left: 49em;
}

.caption-tres {
  max-width: 100%;
  width:20em;
  margin-top: -7.5em;
  margin-left: 80em;
}
.placeholder-images-container {
  display: flex;
  justify-content: center;
  gap: 20em;
  margin-top: 12.5em;
  margin-bottom: -.5em;
  margin-right: 13em;
}

.mentor-cards-background {
  background-color: #111827;
  padding: 2em;
  max-height: 100%;
  margin-top: 0em;
  height: 52em;
  display: flex;
  justify-content: center;
}

.value-background {
  background-color: #111827;
  padding: 0.1em;
  max-height: 100%;
  margin-top: 0em;
  height: 40em;

}

.impress-image {
  margin-left: 38em;
  position: relative;
  bottom: 22em;
  border-radius: 1em;
}
.testimonial-background {
  background-color: white;
  max-width: 100%;
  max-height: 100%;
  height: 40em;
  margin-bottom:2em;

}

.call-to-action-video-container {
  margin-top: 3em;
  position: relative;
  max-width: 100%;
  width: 120em;
  max-height: 100%;

  margin-bottom: -0.5em;

}

.call-to-action-video {
  width: 100%;
  height: auto;
  display: block;
}

.video-title {
  font-family: 'questa-sans', sans-serif;
  color: white;
  font-size: 8em;
  margin-top: 0.5em;
  width: 75%;
  margin-left: 2em;
}

.video-prev-title {
  font-family: 'questa-sans', sans-serif;
  position: relative;
  right: 1.5em;
  color: white;
  font-size: 4em;
  margin-right: 20em;
  margin-top: 3.5em;
  width: 100%;
  margin-left: 2em;
  margin-bottom: -1.5em;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Adjust the alpha value to control the dimness */
  pointer-events: none; /* Allow clicks to pass through to the video */
}

.testimonial-title {
  font-size: 4.5em;
  font-family: 'questa-sans', sans-serif;
  color: #111827;
  margin-bottom: -1.5em;
}
.testimonial-container {
  position: relative;
  top: 10em;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  gap: 3em;
  height: 25em;

}
.first-testimonial-square {
  max-width: 100%;
  width: 32em;

  background-color: white;
  border: 3px solid lightgrey;
  font-size: 1.1em;


}

.second-testimonial-square {
  max-width: 100%;
  width: 32em;
  background-color: white;
  border: 3px solid lightgrey;
  font-size: 1.1em;

}

.third-testimonial-square {

  max-width: 100%;
  background-color: white;
  width: 32em;
  border: 3px solid lightgrey;
  font-size: 1.1em;

}

.first-testimonial-description {
  margin-top: 3em;
  font-family: 'questa-sans', sans-serif;
  width: 75%;
  margin-left: 4em;
  margin-bottom: 0.5em;
  color: #111827;
}

.second-testimonial-description {
  font-family: 'questa-sans', sans-serif;
  margin-top: 3em;
  width: 75%;
  margin-left: 4em;
  margin-bottom: 0.5em;
  color: #111827;
}

.third-testimonial-description {
  font-family: 'questa-sans', sans-serif;
  margin-top: 3em;
  width: 75%;
  font-size: 1em;
  margin-left: 4em;
  margin-bottom: 0.5em;
  color: #111827;
}
.first-testimonial-name {
  margin-top: 1em;
  font-family: 'questa-sans', sans-serif;
  font-weight: bold;
  color: #111827;
  font-size: 1.1em;

}
.second-testimonial-name {
  margin-top: 1em;
  font-family: 'questa-sans', sans-serif;
  font-weight: bold;
  font-size: 1.1em;
  color: #111827;
}
.third-testimonial-name {
  margin-top: 1em;
  font-family: 'questa-sans', sans-serif;
  font-weight: bold;
  color: #111827;
  font-size: 1.1em;
}

.first-testimonial-grade {
  font-family: 'questa-sans', sans-serif;
  margin-top: -.3em;
  color: #111827;
  font-size: 1.1em;

}
.second-testimonial-grade {
  font-family: 'questa-sans', sans-serif;
  margin-top: -.3em;
  color: #111827;
  font-size: 1.1em;
}
.third-testimonial-grade {
  font-family: 'questa-sans', sans-serif;
  margin-top: -.3em;
  color: #111827;
  font-size: 1.1em;
}


.placeholder-image-card {

  display: flex;
  align-items: center;
  width: 10em;
}

.placeholder-image {
  max-width: 100%;
  max-height: 100%;
  margin-right: 0.5em;

}

.caption {
  position: relative;
  top: .25em;
  margin-top: 0.5em;
  font-size: 2.4em;
  text-align: center;
  width: 100%;
  width: 10em;
  color: #111827;
  font-family: "questa-sans", sans-serif;
  font-weight: bold;


}
.caption-caption-one,
.caption-caption-two,
.caption-caption-three {
  position: relative;
  top: 22em;
  right: 10em;

  font-size: 1.2em;
  color: #111827;
  font-family: "questa-sans", sans-serif;
  max-width: 100%;
  width:10em; /* Adjust width as needed */

}

.value-container {
  position: relative;

  margin-left: 7.6em;
  font-family: "questa-sans", sans-serif;
  font-size: 1.5em;
  max-width: 100%;
  width: 30em;
  color: white;
  bottom: 1em;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10em;
}

.text-content {
  flex: 1;
  max-width: 60%;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image {
  max-width: 100%;
  height: auto;
  margin-left: -4em;
  margin-bottom: 1.5em;
  border-radius: 10px;
}

.before-main {
  font-family: "questa-sans", sans-serif;
  font-size: 1.8em;
  margin-right: 3em;
  color: #111827;
  font-style: italic;
}
.main-heading {
  font-size: 3.5em;
  font-weight: bold;
  color: #111827;
  text-align: left;
  margin: 0.5em 1em; /* Adjust margin for better spacing */
  font-family: "Apercu Pro", sans-serif; /* Example of a "bubble" font */
}

.sub-heading {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  max-width: 50em;
  margin: 0 2em; /* Center the container */
  text-align: left; /* Center text inside the container */
  font-size: 1.7em;
  color: #111827;
  margin-top: 1.3em;
}

/* Background container */
.background-container {
  border-radius: 0.5em;
  margin-top: 8.7em;
  position: relative;
  margin-left: 3.1em;
  width: 95%;
  height: 83vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mentors-title-container {
  background-color: #111827;
}

.background-container::before {
  content: "";
  position: absolute;
  top: 0;
  margin-top: 26em;
  width: 125em;
  height: 50vh; /* Top half of the screen */
  background-color: #111827; /* Dark background color */
  z-index: 0; /* Place behind the image */
}

.dimmed-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3); /* Dim the background */
  z-index: -1;
}

.student-list {
  margin-top: 0.5em;
  margin-left: 4em;
  text-align: left;
  font-weight: bold;
  color: #111827;
  font-size: 1.5em;
  font-family: questa-sans, sans-serif;
}

body {
  font-family: "Roboto", sans-serif; /* Apply the font globally */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  overflow: hidden; /* Prevent scroll bars */
}

.white-box {
  background-color: white;
  padding: 1.7em; /* Adjust padding to make the box smaller */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1; /* Ensure the white box is above the background */
  text-align: center;
  max-width: 100%; /* Set max-width to make the box smaller */
  width: 200em;
  height: auto;
  margin: 0 auto; /* Center the white box */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.get-started-button {
  font-family: "questa-sans", sans-serif;
  width: 50%;
  margin-top: 1.5em;
  margin-left: 6em;
  margin-right: 12em;
  background-color: white;
  border: 2px solid black;
  color: #111827;
  padding: 10px 50px;
  font-size: 1.9em;
  border-radius: 10px; /* Rounded edges */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for all properties */
  font-weight: lighter;
  position: relative; /* Necessary for positioning the pseudo-element */
}

.get-started-button::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: -5px;
  bottom: -5px;
  background-color: #111827;
  border-radius: 10px; /* Same rounded edges as the button */
  z-index: -1; /* Place behind the button */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional shadow for a nicer effect */
  transition: all 0.3s ease; /* Smooth transition for all properties */
}

.get-started-button:hover {
  background-color: #111827; /* Change background color to black on hover */
  color: white; /* Change text color to white on hover */
}

.get-started-button:hover::before {
  background-color: white; /* Change shadow background to white on hover */
  border: 2px solid #111827; /* Add black border to the shadow on hover */
  box-shadow: none; /* Remove the shadow effect */
}

.no-underline {
  text-decoration: none;
  color: inherit; /* Inherit color from the parent element */
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center; /* Center text */
  padding: 0.75em 1.5em; /* Adjust padding to match the button */
}

.no-underline:hover {
  color: inherit; /* Prevent color change on hover */
}

.button-underline {
  text-decoration: none;
  color: inherit; /* Inherit color from the parent element */
  display: inline-block;
  width: 100%;
  margin-left: -1.3em;
  height: 100%;
  text-align: center; /* Center text */
  padding: 0.75em 1.5em; /* Adjust padding to match the button */
}

