body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
}

.donate-background {
  background-color: white;
  height: 150%;
  height: 100vh;
  width: 100%;
}

.don-background-background {
  background-color: #f6f5f2;
  width: 100%;
  height: 100vh;
}

.don-background {
  margin-top: 12.9em; /* Moves the entire background down */
  margin-left: 2.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 10em); /* Adjust height to account for margin */
  background-color: #111827;
  width: 96.5%; /* Adjust the width as needed */
  max-width: 118em; /* Optional max-width to prevent it from getting too wide */
  max-height: 30em;
  padding: 0.5em; /* Add some padding for inner spacing */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  border-radius: 10px; /* Optional: Add rounded corners */
}

.donate-container {
  position: relative;
  width: 60%;
  height: 60vh;
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  justify-content: flex-start; /* Align children at the top */
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.donate-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover; /* Ensure the image covers the container */
}

.donate-content {
  position: absolute; /* Fix position relative to donate-container */
  top: 20%; /* Adjust this value to move it vertically */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  font-family: "questa-sans", sans-serif;
  font-style: normal;
  letter-spacing: -0.05em;
  font-weight: bold;
  font-size: 7em;
  margin-top: 1.3em;
  text-align: center;
  /* Remove margin */
}

.donate-box {
  position: relative;

  border: 2px solid black;
  background-color: white;
  padding: 1.7em;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
  text-align: center;
  width: 100%; /* Set width to make the box larger */
  max-width: 90%; /* Set max-width to make the box smaller */
  margin-top: 30.5em; /* Move the box down independently */
  margin-bottom: 2em; /* Create space below the box */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donate-text .donate-heading {
  font-size: 4em;
  margin-bottom: 3em;
  color: #111827;
  margin-top: 1em;
  font-size: 1.6em;
  font-weight: lighter;
  font-family: "questa-sans", sans-serif;
  font-style: normal;
}

.donate-btn {
  background-color: #111827; /* Change background color to black on hover */
  color: white; /* Change text color to white on hover */
  cursor: pointer;
  padding-block: 1em;
  width: 100%;
  width: 55em;
  border-radius: 0.5em;
  background-image: linear-gradient(to right, var(--primary-linear-gradient));
  border: 3px solid black;
  outline: transparent;
  transition: 0.125s ease;
  font-family: "questa-sans", sans-serif;
  font-style: normal;
}
