/* Login.css */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #111827; /* Light background color */
}
.form-heading {
  font-family: "questa-sans", sans-serif;
  font-style: normal; /* Apply Apercu Pro font */
  font-size: 3em; /* Adjust the font size as needed */
  color: white;
  text-align: center;
  margin-top: 3em;
  margin-bottom: 1em; /* Add some margin below the heading */
  margin-right: -4em;
  margin-left: auto;
}

.login-container > div {
  width: 100%;
  max-width: 70em; /* Adjust the max-width to control the form width */
  padding: 15em 9em; /* Increase padding for a larger and taller form */

  margin-left: 20em;
  background-color: white; /* Form background color */
}

.login-container h2 {
  text-align: center;
  margin-bottom: 1.5em; /* Adjust margin for larger form */
}

.login-container .supabase-auth-ui {
  display: flex;
  flex-direction: column;
  gap: 2em; /* Increase gap between elements */
}

.supabase-auth-ui input[type="email"],
.supabase-auth-ui input[type="password"] {
  width: 100%;
  padding: 1.5em; /* Increase padding for larger input fields */
  margin: 0.5em 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.2em; /* Increase font size */
}

.supabase-auth-ui button {
  width: 100%;
  padding: 1.5em; /* Increase padding for larger button */
  background-color: white;
  color: #111827;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.supabase-auth-ui button:hover {
  background-color: grey;
}

.supabase-auth-ui .supabase-provider-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em; /* Increase padding for larger provider button */
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.supabase-auth-ui .supabase-provider-button:hover {
  background-color: #e0e0e0;
}

.supabase-auth-ui .supabase-provider-button span {
  margin-left: 0.5em;
}

.supabase-auth-ui .supabase-auth-link {
  text-align: center;
  margin-top: 1.5em; /* Adjust margin for larger form */
  font-size: 1em; /* Increase font size */
  color: #007bff;
  text-decoration: none;
}

.supabase-auth-ui .supabase-auth-link:hover {
  text-decoration: underline;
}
